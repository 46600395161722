<template>
<div class="page_content_wrap">
  <h1>Whoops! 404.</h1>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>